<template>
  <div class="rightLink">
   <!-- <div class="operate_button">
      <h5 class="title_s">
        <el-icon class="icon"><Search /></el-icon> 角色查询
      </h5>
       <el-form :inline="true">
        <el-form-item label="">
          <el-input
            clearable
            v-model="searchData.keyword"
            placeholder="请输入查询关键字"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="buttonPri"
            @click="fetchData"
            ><el-icon><Search /></el-icon> 查询</el-button
          >
        </el-form-item>
      </el-form>
    </div> -->
    <div class="bottomDiv" style="padding:0px 25px;height:750px">
      <h5 class="title" style="margin-bottom: 5px;"><i class="glyphicon glyphicon-user"></i> 角色列表</h5>
      <el-button
      style="width:110px"
        class="buttonPri"
        @click="(addRoleDiaLogFlag = true), (DiaLogFlagTitle = '角色添加')"
      >
        <i class="glyphicon glyphicon-plus"></i> 新增角色
      </el-button>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 95 %;"
        height="630px"
        stripe
        size="medium"
        :header-row-style="{ color: '#333' }"
      >
        <el-table-column prop="id" label="序号" type="index" width="100" />
        <el-table-column prop="name" label="角色名称" width="180" />
       
        <el-table-column label="操作" min-width="184" fixed="right">
          <template #default="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-trash"></i> 删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div v-dialogdrag>
        <el-dialog
        :before-close="closeDialog"
        v-model="addRoleDiaLogFlag"
        :title="DiaLogFlagTitle"
      >
        <el-form :model="form" style="width:70%;margin:0 auto">
          <!--   -->
          <!-- <el-form-item label="角色id" label-width="120px">
            <el-input clearable disabled v-model="form.id" autocomplete="off"></el-input>
          </el-form-item> -->
          <el-form-item label="角色名" label-width="80px">
            <el-input clearable v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button type="primary" class="buttonPri" @click="submitDiaLog"
              >确认</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    </div>
    <!-- 弹框from -->
    
  </div>
</template>

<style scoped>
.el-tree {
  height: 300px;
  overflow-y: scroll;
}
.iftext {
  width: 800px;
  height: 500px;
}
.el-pagination /deep/ .is-background .el-pager li{
  margin: 0;
  background: none;
  border: 1px solid #eee;
  border-radius: 0;
}
.el-pagination.is-background /deep/ .btn-next,
.el-pagination.is-background .btn-prev {
  margin: 0;
  border: 1px solid #eee;
  border-radius: 0;
  background: #fff;
}

.el-pagination.is-background /deep/ .el-pager li:not(.disabled).active{
  background-color: #03bfd4;
}
</style>

<script>
import {
  role,
  add_role,
  edit_role,
  delete_role,
  // search_permission,
  // search_pagepermission,, ref
} from "@/request/api";
import { onMounted, reactive, toRefs } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  name: "sysConfigrole",
  setup() {
    const data = reactive({
      tableData: [], // 表格数据容器
      page: 1,
      current_page: 10,
      total: 0, // 条目总数
      DiaLogFlagTitle: "", // 弹框title
      addRoleDiaLogFlag: false, // 角色添加弹框显示
      loading: false,
      // 弹框表单数据容器
      form: {
        name: "",
        id:""
        // permission_ids: [],
        // description: "",
        // pagepermission_ids: [],
      },
      // 弹框类型
      dialogType: true,
      // 搜索
      searchData: {
        keyword: "",
      },
      // 暂存操作角色id
      role_id: "",
      // 权限列表存储
      powerList: [],
      // 弹框 页面控制
      routerDiaLog: false,
      // 页面控制 数据容器 (页面列表容器)
      routerData: [],
      routerProps: {
        children: "children",
        label: "label",
      },
      acitveRouter: [], // 选中的页面
    });
    // 请求数据获取角色列表
    const fetchData = () => {
      // data.loading = true;
      const sdata = {
        keyword: data.searchData.keyword,
        page: data.page,
        current_page: data.current_page,
      };
      role(sdata)
        .then((res) => {
          if (res) {
            data.tableData = res.data;
            data.loading = false;
            data.total = res.total;
          } else {
            data.tableData = [];
            data.loading = false;
          }
        })
        .catch(() => {
          data.tableData = [];
          data.loading = false;
        });
    };
  
    // // 表格内点击编辑按钮 编辑角色
    const handleEdit = (index, row) => {
      console.log(row,'row111')
      data.dialogType = false;
      data.DiaLogFlagTitle = "角色编辑";
      data.form.id = row.id;
      data.form.name = row.name;
      data.addRoleDiaLogFlag = true;
      data.role_id = row.id;
    };
    // 表格内点击删除按钮 删除角色
    const handleDelete = (index, row) => {
      console.log(row,'--==--');
      ElMessageBox.confirm("您确定要删除角色吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const dataa = {
          roles_id: row.id,
        };
        delete_role(dataa).then((res) => {
          if (res) {
            ElMessage.success("删除成功");
            fetchData();
          }
        });
      });
    };
    // // 弹框确认按钮
    const submitDiaLog = () => {
      if (data.dialogType) {
        // 添加
        console.log(data,'000');
        const dataa = {
          roles_name: data.form.name
        }
        dataa.pagepermission_ids = data.acitveRouter;
        add_role(dataa).then((res) => {
          if (res) {
            fetchData();
            ElMessage.success("添加成功");
          }
        });
      } else {
        // 编辑
        const dataa1 = {
          roles_id:data.role_id,
          roles_name:data.form.name
        };
        console.log(dataa1,'dataa1')
        edit_role(dataa1).then((res) => {
          if (res) {
            fetchData();
            ElMessage.success("编辑成功");
          }
        });
        data.role_id = "";
      }
      closeDialog();
    };
    // // 初始化弹框
    const closeDialog = () => {
      data.addRoleDiaLogFlag = false;
      data.form = {
        id: "",
        roles_name: "",
        // permission_ids: [],
        // pagepermission_ids: [],
      };
      data.acitveRouter = [];
      // tree.value.setCheckedKeys([], false);
    };
    // // 打开页面控制弹框
    // const OpenrouterDiaLog = (row) => {
    //   data.routerDiaLog = true;
    //   data.roles_name = row.roles_name;
    //   data.id = row.id;
    // };
    // const tree = ref(null);
    // // 选择页面时触发
    // const handleNodeClick = (...obj) => {
    //   let a = obj[1].checkedNodes.filter((a) => a.node === 2);
    //   data.acitveRouter = a.map((b) => b.id);
    // };
    // // 获取页面路由列表
    // const getRouterList = () => {
    //   const dataa = {
    //     keyword: "",
    //     current_page: 1,
    //     page: 100,
    //   };
    //   search_pagepermission(dataa)
    //     .then((res) => {
    //       if (res) {
    //         const Basic = res.data;
    //         arr2Json(Basic);
    //       } else {
    //         data.loading = false;
    //       }
    //     })
    //     .catch(() => {
    //       data.tableData = [];
    //       data.loading = false;
    //     });
    // };
    // // 转换为tree所需要的格式
    // const arr2Json = (Basic) => {
    //   const list = Basic;
    //   const a = list.map((a) => a.grandParentPath);
    //   const b = list.map((a) => a.parent);
    //   const modu = Array.from(new Set(a));
    //   const parent = Array.from(new Set(b));
    //   let node1 = [];
    //   let c = [];

    //   modu.forEach((b) => {
    //     if (b == "user") {
    //       c.push({
    //         label: "征收户管理",
    //         path: "user",
    //         children: [],
    //       });
    //     } else if (b == "financial_manage") {
    //       c.push({
    //         label: "账务管理",
    //         path: "financial_manage",
    //         children: [],
    //       });
    //     } else if (b == "data_manage") {
    //       c.push({
    //         label: "数据管理",
    //         path: "data_manage",
    //         children: [],
    //       });
    //     } else if (b == "sys_config") {
    //       c.push({
    //         label: "系统配置",
    //         path: "sys_config",
    //         children: [],
    //       });
    //     } else if (b == "gis") {
    //       c.push({
    //         label: "GIS地图",
    //         path: "gis",
    //         children: [],
    //       });
    //     } else if (b == "archives_manage") {
    //       c.push({
    //         label: "案卷管理",
    //         path: "archives_manage",
    //         children: [],
    //       });
    //     } else if (b == "team") {
    //       c.push({
    //         label: "协作管理",
    //         path: "team",
    //         children: [],
    //       });
    //     }
    //   });
    //   const creatNode1 = new Promise((res) => {
    //     parent.forEach((item, index) => {
    //       parent[index] = {
    //         label: item,
    //         children: [],
    //         parent: "",
    //       };
    //     });
    //     node1 = parent;
    //     res(node1);
    //   });
    //   const creatNode2 = new Promise((res) => {
    //     creatNode1.then((res) => {
    //       res.forEach((p) => {
    //         list.forEach((l) => {
    //           if (p.label == l.parent) {
    //             p.children.push(l);
    //             p.parent = l.grandParentPath;
    //           }
    //         });
    //       });
    //       node1 = res;
    //     });
    //     res(node1);
    //   });
    //   creatNode2.then((res) => {
    //     c.forEach((c) => {
    //       res.forEach((r) => {
    //         if (c.path == r.parent) {
    //           c.children.push(r);
    //         }
    //       });
    //     });
    //     data.routerData = c;
    //   });
    // };
    onMounted(() => {
      fetchData();
      // fetchPowerData();
      // getRouterList();
    });
    return {
      ...toRefs(data),
      handleEdit,
      handleDelete,
      submitDiaLog,
      fetchData,
      closeDialog,
      // OpenrouterDiaLog,
      // handleNodeClick,
      // tree,
    };
  },
};
</script>
